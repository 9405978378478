<script setup lang="ts">
const { t } = useI18n();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const navigation = ref([
  {
    path: "/account",
    title: t("account.myCustomerAccount"),
    tags: [t("account.menu.overview")],
  },
  {
    path: "/account/order",
    title: t("account.menu.orders"),
    tags: [t("account.menu.shippingStatus"), t("account.menu.return")],
  },
  {
    path: "/account/wishlist",
    title: t("account.menu.wishlist"),
    tags: [t("account.menu.shippingStatus"), t("account.menu.return")],
  },
  {
    path: "/account/address",
    title: t("account.addresses"),
    tags: [t("account.menu.shippingAddress"), t("account.menu.billingAddress")],
  },
  {
    path: "/account/payment",
    title: t("account.paymentMethods"),
    tags: [t("account.menu.paymentMethod")],
  },
  {
    path: "/account/profile",
    title: t("account.myAccount.myProfile"),
    tags: [t("account.menu.personalDetails"), t("account.menu.settings")],
  },
  // {
  //     path: "/account/newsletter",
  //     title: t("account.menu.newsletter"),
  //     tags: [t("account.menu.settings"), t("account.menu.manageNewsletter")]
  // },
]);
</script>

<template>
  <div class="bg-scheppach-primary-50">
    <NuxtLink
      v-for="entry in navigation"
      :key="entry.path"
      class="block px-5 py-3 cursor-pointer c-scheppach-primary-500 duration-200"
      :to="formatLink(entry.path)"
    >
      <div class="text-[20px] inline-block">
        {{ entry.title }}
      </div>
      <div class="flex items-center flex-row flex-wrap gap-1">
        <p class="font-500 text-[16px]">
          {{ entry.tags.join(", ") }}
        </p>
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped>
.link-active {
  background-color: transparent;
  border-left: 6px solid var(--scheppach-primary-500);
}

.link-active > :first-child {
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 5px;
}
</style>
